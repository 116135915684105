var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-business"},[_c('div',{staticClass:"title-business"},[_c('b-card-title',{staticClass:"title-text",attrs:{"title-tag":"h1"}},[_vm._v(" "+_vm._s(_vm.$t('profile.textGeneral'))+" ")])],1),_c('div',{staticClass:"form-business"},[_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"formValidation"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":_vm.refCode && _vm.refMode ? '6' : '12'}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"email","name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"name":"email","disabled":"","state":errors.length > 0 ? false : null},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userData.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.refCode && _vm.refMode)?_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('common.labelReferral'))+" "),_c('span',{staticClass:"text-danger"})])]),_c('validation-provider',{attrs:{"name":_vm.$t('common.labelReferral')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"name":"referralCode","disabled":"","state":errors.length > 0 ? false : null},model:{value:(_vm.refCode),callback:function ($$v) {_vm.refCode=$$v},expression:"refCode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3221855784)})],1)],1):_vm._e()],1),_c('b-row',{staticClass:"select-country"},[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"fullName"}},[_vm._v(" "+_vm._s(_vm.$t('common.labelFullName'))+" ( "+_vm._s(_vm.$t('common.textOr'))+" "+_vm._s(_vm.$t('common.labelBusinessName'))+" )"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"fullName","name":_vm.$t('common.labelFullName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"name":"full name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.placeholderFullName')},model:{value:(_vm.userData.fullName),callback:function ($$v) {_vm.$set(_vm.userData, "fullName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userData.fullName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.isVNWhiteLabel)?_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('profile.textBusinessModel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelBusinessModel'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('v-select',{staticClass:"currency input-height",attrs:{"placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.placeholderBusinessModel'),"tabindex":2,"clearable":false,"options":_vm.optionsBusinessModel},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}}],null,true),model:{value:(_vm.userData.businessModel),callback:function ($$v) {_vm.$set(_vm.userData, "businessModel", $$v)},expression:"userData.businessModel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,false,1370663371)})],1):_vm._e(),(!_vm.isVNWhiteLabel)?_c('b-col',{attrs:{"sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('stepGuideControl.stepVerifyInfo.labelBusinessType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelBusinessType'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('v-select',{staticClass:"currency input-height",attrs:{"placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.labelBusinessType'),"tabindex":2,"clearable":false,"options":_vm.optionsBusinessType},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}}],null,true),model:{value:(_vm.userData.type),callback:function ($$v) {_vm.$set(_vm.userData, "type", $$v)},expression:"userData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,false,443313384)})],1):_vm._e(),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('stepGuideControl.stepVerifyInfo.labelCountry')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelCountry'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('country-select',{staticClass:"input-height country",attrs:{"placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.labelCountry'),"country-name":true,"autocomplete":true,"tabindex":3,"country":_vm.userData.country,"disabled":!_vm.canSelectCountry},model:{value:(_vm.userData.country),callback:function ($$v) {_vm.$set(_vm.userData, "country", $$v)},expression:"userData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}])})],1),(!_vm.isVNWhiteLabel)?_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('stepGuideControl.stepVerifyInfo.labelCity')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelCity'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('region-select',{staticClass:"input-height region",attrs:{"country-name":true,"region-name":true,"autocomplete":true,"tabindex":4,"country":_vm.userData.country,"region":_vm.userData.city,"disabled":_vm.canSelectRegion,"placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.selectCountry')},model:{value:(_vm.userData.city),callback:function ($$v) {_vm.$set(_vm.userData, "city", $$v)},expression:"userData.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,false,2141734836)})],1):_vm._e()],1),(!_vm.isVNWhiteLabel)?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelStreetAddress'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"street-address","rules":"required","name":_vm.$t('stepGuideControl.stepVerifyInfo.labelStreetAddress')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"name":"street address","placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.placeholderStreetAddress'),"tabindex":5,"state":errors.length > 0 ? false : null},model:{value:(_vm.userData.streetAddress),callback:function ($$v) {_vm.$set(_vm.userData, "streetAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userData.streetAddress"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3165943795)})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelPostalCode'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"rules":"required|postalCodeLength","name":_vm.$t('stepGuideControl.stepVerifyInfo.labelPostalCode')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"name":"postal-code","placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.placeholderPostalCode'),"tabindex":6,"state":errors.length > 0 ? false : null},model:{value:(_vm.userData.postalCode),callback:function ($$v) {_vm.$set(_vm.userData, "postalCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userData.postalCode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,257169522)})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('stepGuideControl.stepVerifyInfo.labelWhatPlatform')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelWhatPlatform'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('v-select',{staticClass:"currency input-height",attrs:{"placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.placeholderWhatPlatform'),"tabindex":9,"clearable":false,"options":_vm.optionsStoringPlatform},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}}],null,true),model:{value:(_vm.userData.storingPlatform),callback:function ($$v) {_vm.$set(_vm.userData, "storingPlatform", $$v)},expression:"userData.storingPlatform"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,false,4051730112)})],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('stepGuideControl.stepVerifyInfo.labelWhatIsYourMonthly')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelWhatIsYourMonthly'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('v-select',{staticClass:"currency input-height",attrs:{"placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.placeholderWhatIsYourMonthly'),"tabindex":10,"clearable":false,"options":_vm.optionsMonthlyRevenue},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}}],null,true),model:{value:(_vm.userData.monthlyRevenue),callback:function ($$v) {_vm.$set(_vm.userData, "monthlyRevenue", $$v)},expression:"userData.monthlyRevenue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,false,1714949656)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"isRunTikTok","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelHaveYouRunTiktok'))+" ")])]),_c('v-select',{staticClass:"currency input-height",attrs:{"placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.placeholderHaveYouRunTiktok'),"tabindex":11,"clearable":false,"options":_vm.optionsHasRunTikTok},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}}],null,true),model:{value:(_vm.userData.hasRunTikTokAds),callback:function ($$v) {_vm.$set(_vm.userData, "hasRunTikTokAds", $$v)},expression:"userData.hasRunTikTokAds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}],null,false,1487299095)})],1)],1):_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"industry"}},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIndustry'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"industry","name":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIndustry'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"id":"industry","name":"industry","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderIndustry')},model:{value:(_vm.userData.industry),callback:function ($$v) {_vm.$set(_vm.userData, "industry", $$v)},expression:"userData.industry"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"shopCode"}},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelProductUrl'))+" ")])]),_c('validation-provider',{attrs:{"id":"product-link","rules":"url|productLink","name":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelProductUrl')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height input-product-link",attrs:{"id":"verify-product-link","data-testid":"verify-product-link","name":"product link","placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderProductUrl'),"tabindex":1,"disabled":""},model:{value:(_vm.productsData.productURL),callback:function ($$v) {_vm.$set(_vm.productsData, "productURL", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"productsData.productURL"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"shopCode"}},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelTikTokShopCode'))+" ")])]),_c('validation-provider',{attrs:{"id":"shopCode","name":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelTikTokShopCode')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"id":"shop-code","name":"shopCode","disabled":"","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeHolderTikTokShopCode')},model:{value:(_vm.userData.shopCode),callback:function ($$v) {_vm.$set(_vm.userData, "shopCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userData.shopCode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-card-title',{staticClass:"title-text mt-2",attrs:{"title-tag":"h1"}},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textPrimaryContact'))+" ")]),(!_vm.isEmediaPlatform)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('phone-input',{attrs:{"phone-data":_vm.phoneData,"disabled":""},on:{"update:phoneData":function($event){_vm.phoneData=$event},"update:phone-data":function($event){_vm.phoneData=$event}}})],1)],1)],1):_vm._e(),(!_vm.isVNWhiteLabel)?_c('b-row',[(!_vm.isEmediaPlatform)?_c('b-col',{attrs:{"cols":"12"}},[(_vm.userData.contactInfo)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"connectFacebook"}},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelConnectViaFacebook'))+" ")])]),_c('validation-provider',{attrs:{"id":"connectFacebook","name":_vm.$t('stepGuideControl.stepVerifyInfo.labelConnectViaFacebook')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge ",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"prepend":"https://www.facebook.com/"}},[_c('b-form-input',{staticClass:"input-height input-facebook",attrs:{"id":"connect-facebook","name":"connectFacebook","disabled":"","state":errors.length > 0 ? false : null},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return (function (event) { return event.preventDefault(); })($event)}},model:{value:(_vm.userData.contactInfo.facebook),callback:function ($$v) {_vm.$set(_vm.userData.contactInfo, "facebook", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userData.contactInfo.facebook"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,962410667)})],1):_vm._e()],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[(_vm.userData.contactInfo)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"connectWhatsapp"}},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelConnectViaWhatsApp'))+" ")])]),_c('validation-provider',{attrs:{"id":"connectWhatsapp","name":_vm.$t('stepGuideControl.stepVerifyInfo.labelConnectViaWhatsApp')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"id":"connect-whatsApp","name":"connectWhatsapp","type":"number","disabled":"","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.placeholderConnectViaWhatsApp')},model:{value:(_vm.userData.contactInfo.whatsApp),callback:function ($$v) {_vm.$set(_vm.userData.contactInfo, "whatsApp", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userData.contactInfo.whatsApp"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4277171443)})],1):_vm._e()],1),(!_vm.isEmediaPlatform)?_c('b-col',{attrs:{"cols":"12"}},[(_vm.userData.contactInfo)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"connectTelegram"}},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.labelConnectViaTelegram'))+" ")])]),_c('validation-provider',{attrs:{"id":"connectTelegram","rules":"telegramLength|valExcludeUrl","name":_vm.$t('stepGuideControl.stepVerifyInfo.labelConnectViaTelegram')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"id":"connect-telegram","name":"connectTelegram","disabled":"","placeholder":"@username","state":errors.length > 0 ? false : null},model:{value:(_vm.userData.contactInfo.telegram),callback:function ($$v) {_vm.$set(_vm.userData.contactInfo, "telegram", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userData.contactInfo.telegram"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2333929663)})],1):_vm._e()],1):_vm._e()],1):_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[(_vm.userData.contactInfo)?_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"connectZalo"}},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.placeholderConnectViaZalo'))+" ")])]),_c('validation-provider',{attrs:{"id":"connectZalo","name":_vm.$t('stepGuideControl.stepVerifyInfo.placeholderConnectViaZalo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"id":"connect-zalo","name":"connectZalo","disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Enter your Zalo number"},model:{value:(_vm.userData.contactInfo.zalo),callback:function ($$v) {_vm.$set(_vm.userData.contactInfo, "zalo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userData.contactInfo.zalo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3067717234)})],1):_vm._e()],1)],1),_c('div',{staticClass:"btn-control"},[_c('btn-loading',{attrs:{"variant-convert":"btn-submit","pill":"","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('common.btnSaveChanges'))+" ")])],1)],1)],1)],1)],1)],1),_c('verify-phone-modal',{ref:"modal-verify-phone",attrs:{"params":_vm.params},on:{"resendCode":_vm.submit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }